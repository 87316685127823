import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu"]

  connect() {
    console.log("connecting homepage_menu", this);
    this.toggleClass = 'invisible'

    this.menuEnteringClasses = ["transition","ease-out","duration-200"];
    this.menuLeavingClasses = ["transition", "ease-in", "duration-100", "transform"];

    this.menuVisibleClasses = ["opacity-100", "scale-100"];
    this.menuInvisibleClasses = ["opacity-0", "scale-95"];

    this.close();
  }

  open() {
    this.menuTarget.classList.remove(this.toggleClass);
    this.menuTarget.classList.add(...this.menuEnteringClasses);
    this.menuTarget.classList.add(...this.menuVisibleClasses);
    this.menuTarget.classList.remove(...this.menuInvisibleClasses);
  }

  close() {
    this.menuTarget.classList.add(this.toggleClass);
    this.menuTarget.classList.add(...this.menuLeavingClasses);
    this.menuTarget.classList.add(...this.menuInvisibleClasses);
    this.menuTarget.classList.remove(...this.menuVisibleClasses);
  }
}
