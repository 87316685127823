import { Controller } from "stimulus"
import { throttle, debounce } from "throttle-debounce";
import * as ace from 'ace-builds/src-noconflict/ace'
require("ace-builds/webpack-resolver");
import 'ace-builds/src-noconflict/mode-css.js'
import 'ace-builds/src-noconflict/theme-github'

export default class extends Controller {
  static targets = [ "content" ]

  initialize() {
    this.updateTextArea = debounce(300, this.updateTextArea);
  }

  connect() {
    console.log("setting up editor");
    this._textArea = this.element.querySelector('textarea');
    this._textArea.classList.add('hidden');

    // data-attributes for the edit_form_controller.js
    this._textArea.setAttribute('data-edit-form-target', 'css');
    this._textArea.setAttribute('data-action', 'change->edit-form#updatePreview');

    var node = document.createElement('div');
    node.classList.add('ace-editor');
    this.element.appendChild(node);

    let editor = ace.edit(node);
    editor.setTheme('ace/theme/github');
    editor.session.setMode("ace/mode/css");

    editor.setValue(this._textArea.value);

    editor.commands.on('afterExec', (cmd) => {
      this.updateTextArea(editor);
    });

    editor.session.on('change', (delta) => {
      this._textArea.value = editor.getValue();
    });

    this._editor = editor;
  }

  updateTextArea(editor) {
    this._textArea.value = editor.getValue();
    const e = new Event("change");
    this._textArea.dispatchEvent(e);
  }

  disconnect() {
    console.log("destroying editor");
    this._editor.destroy();
  }

}
