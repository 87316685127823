import { Toggle } from "tailwindcss-stimulus-components"


export default class extends Toggle {
  connect() {
    console.log("my toggle ready to party");
    super.connect();
  }

  toggle(event) {
    super.toggle(event);

    if (this.openValue) {
      this.checkbox.setAttribute('checked', 'checked');
    } else {
      this.checkbox.removeAttribute('checked');
    }
  }

  get checkbox() {
    if (!this._checkbox) {
      this._checkbox = this.element.querySelector('input[type=checkbox]')
    }

    return this._checkbox;
  }
}
