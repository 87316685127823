import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sidebar", "menu", "overlay"]

  connect() {
    this.toggleClass = 'invisible';

    this.overlayEnteringClasses = ["transition-opacity", "ease-linear", "duration-300"];
    this.overlayLeavingClasses = ["transition-opacity", "ease-linear", "duration-300"];
    this.overlayVisibleClasses = ["opacity-100"];
    this.overlayInvisibleClasses = ["opacity-0"];

    this.menuEnteringClasses = ["transition","ease-in-out","duration-300", "transform"];
    this.menuLeavingClasses = ["transition", "ease-in-out", "duration-300", "transform"];
    this.menuVisibleClasses = ["translate-x-0"];
    this.menuInvisibleClasses = ["-translate-x-full"];

    this.close();
  }

  open() {
    this.sidebarTarget.classList.remove(this.toggleClass);
    this.overlayTarget.classList.remove(...this.overlayLeavingClasses);

    this.overlayTarget.classList.add(...this.overlayEnteringClasses);
    this.overlayTarget.classList.add(...this.overlayVisibleClasses);
    this.overlayTarget.classList.remove(...this.overlayInvisibleClasses);

    this.menuTarget.classList.add(...this.menuEnteringClasses);
    this.menuTarget.classList.add(...this.menuVisibleClasses);
    this.menuTarget.classList.remove(...this.menuInvisibleClasses);
  }

  close() {
    this.sidebarTarget.classList.add(this.toggleClass);
    this.overlayTarget.classList.remove(...this.overlayEnteringClasses);

    this.overlayTarget.classList.add(...this.overlayLeavingClasses);
    this.overlayTarget.classList.add(...this.overlayInvisibleClasses);
    this.overlayTarget.classList.remove(...this.overlayVisibleClasses);

    this.menuTarget.classList.add(...this.menuLeavingClasses);
    this.menuTarget.classList.add(...this.menuInvisibleClasses);
    this.menuTarget.classList.remove(...this.menuVisibleClasses);
  }


}
