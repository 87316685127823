import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    displayEmail: String,
    address: String
  }

  connect() {
    window.onstorage = (e) => {
      if (e.key == this.element.id) {
        let message = JSON.parse(e.newValue);
        let contactUsPage = Object.assign(H2RU.contactUsPage, message)
        H2RU.contactUsPage = contactUsPage
        H2RU.render();
      }
    }

  }

  get rerender() {
    return this.element.dataset.rerender === "true";
  }
}
