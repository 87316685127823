import { Controller } from "stimulus"
import { throttle, debounce } from "throttle-debounce";

export default class extends Controller {
  static targets = ["displayEmail", "phone", "content", "address", "thankYouMessage", "css"]

  static values = {
    id: Number
  }

  connect() {
  }

  initialize() {
    this.pressKey = debounce(300,this.pressKey);
  }

  pressKey(event) {
    this.updatePreview();
  }

  updatePreview() {
    let configuration = {
      displayEmail: this.displayEmailTarget.value,
      phone: this.phoneTarget.value,
      content: this.contentTarget.value,
      address: this.addressTarget.value,
      thankYouMessage: this.thankYouMessageTarget.value,
      css: this.cssTarget.value
    }

    localStorage.setItem(`contact_us_page_${this.idValue}`, JSON.stringify(configuration));
  }

}
